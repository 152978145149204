<template>
  <div class="listtypesearch">
    <Top></Top>
    <Fix></Fix>
    <div class="listtypesearch-box">
      <div class="listtypesearch-bread">
        <div class="listtypesearch-bread-con">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/listtype' }"
              >商标分类</el-breadcrumb-item
            >
            <el-breadcrumb-item>搜索</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="listtypesearch-search">
        <div>
          <input
            type="text"
            placeholder="请输入要查询的商品/服务编码或名称"
            v-model="word"
          />
          <div @click="regsearch">搜索</div>
        </div>
      </div>
      <div class="listtypesearch-detail">
        <div class="three-link" v-for="(v, k) in searcharr" :key="k">
          <div class="link-div1" @click="stretch1(v.categoryCode)">
            <i
              :class="
                secondshow == isTags(v.categoryCode)
                  ? 'el-icon-caret-bottom'
                  : 'el-icon-caret-right'
              "
            ></i>
            第{{ v.categoryCode }}类 {{ v.categoryName }}
          </div>
          <div
            class="link-div2"
            v-for="(item, index) in v.categorysList"
            v-show="isTags(v.categoryCode)"
            :key="index"
          >
            <div @click="stretch2(item.categoryCode)">
              <i
                :class="
                  thirdshow == isTags2(item.categoryCode)
                    ? 'el-icon-caret-bottom'
                    : 'el-icon-caret-right'
                "
              ></i>
              {{ item.categoryCode }} {{ item.categoryName }}
            </div>
            <div
              class="link-div3"
              v-show="isTags2(item.categoryCode)"
              v-for="(m, n) in item.categorysList"
              :key="n"
            >
              <div class="flex-row">
                <span
                  class="flex-span"
                  v-for="(item, index) in getColor(m.categoryName, word)"
                  :key="index"
                  :style="{ color: item.c || '#333' }"
                  >{{ item.t }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from "../ui/top.vue";
import Foot from "../ui/foot.vue";
import Fix from "../ui/fix.vue";
export default {
  name: "listtypesearch",
  data() {
    return {
      word: "",
      searcharr: [],
      firstid: [],
      secondid: [],
      secondshow: true,
      thirdshow: true,
    };
  },
  components: {
    Top,
    Foot,
    Fix,
  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 3000,
      });
    },
    getColor(name = "", word = "") {
      return name.split("").map((a, b) => {
        return (a = {
          t: a,
          c: word.indexOf(a) == -1 ? "" : "red",
        });
      });
    },
    regsearch() {
      this.$http
        .post(this.GLOBAL.new_url + "/goods/service/categorys/queryCategorys", {
          categoryName: this.word,
        })
        .then((res) => {
          // console.log(res)
          if (res.data.code == "200") {
            this.searcharr = res.data.data;
            for (var i = 0; i < this.searcharr.length; i++) {
              this.firstid.push(this.searcharr[i].categoryCode);
            }
            for (var i = 0; i < this.searcharr.length; i++) {
              for (var j = 0; j < this.searcharr[i].categorysList.length; j++) {
                this.secondid.push(
                  this.searcharr[i].categorysList[j].categoryCode
                );
                if (
                  Number(this.searcharr[i].categorysList[j].categoryCode < 1000)
                ) {
                  this.searcharr[i].categorysList[j].categoryCode =
                    "0" + this.searcharr[i].categorysList[j].categoryCode;
                }
              }
            }
          } else {
            this.tip("error", res.data.msg);
          }
        });
    },
    stretch1(val) {
      if (this.firstid.includes(val)) {
        for (var i = 0; i < this.firstid.length; i++) {
          if (val == this.firstid[i]) {
            this.firstid.splice(i, 1);
          }
        }
      } else {
        this.firstid.push(val);
      }
    },
    stretch2(val) {
      if (this.secondid.includes(val)) {
        for (var i = 0; i < this.secondid.length; i++) {
          if (val == this.secondid[i]) {
            this.secondid.splice(i, 1);
          }
        }
      } else {
        this.secondid.push(val);
      }
    },
    isTags(val) {
      if (this.firstid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    isTags2(val) {
      if (this.secondid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.$route.query.word) {
      this.word = this.$route.query.word;
      this.regsearch();
    } else {
      this.regsearch();
    }
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: auto;
}
.flex-span {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.listtypesearch-box {
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  /* border: 1px solid red; */
}
.listtypesearch-bread {
  width: 100%;
  height: 28px;
  padding-top: 12px;
  background-color: #f8f8f8;
}
.listtypesearch-bread-con {
  width: 980px;
  margin: 0 auto;
}
.listtypesearch-search {
  width: 100%;
  height: 107px;
  background-color: white;
  padding-top: 36px;
}
.listtypesearch-search > div {
  width: 960px;
  height: 71px;
  background-color: #f9f8f8;
  margin: 0 auto;
}
.listtypesearch-search > div > input {
  float: left;
  width: 776px;
  height: 54px;
  border: solid 1px #e1e2e2;
  outline: none;
  padding-left: 10px;
  margin: 8px 0 0 10px;
}
.listtypesearch-search > div > div {
  float: left;
  width: 154px;
  height: 54px;
  background-color: #ff6b00;
  margin-top: 8px;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
  font-size: 16px;
  color: white;
}
.listtypesearch-detail {
  width: 1000px;
  min-height: 400px;
  padding: 50px 0 50px;
  margin: 0 auto;
}
.link-div1 {
  margin-bottom: 10px;
  font-size: 16px;
  height: 23px;
  cursor: pointer;
  color: #333333;
}
.link-div2 {
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  color: #333333;
}
.link-div3 {
  margin-left: 60px;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #333333;
}
.el-icon-caret-right,
.el-icon-caret-bottom {
  color: #EB5E00;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 14px;
}
</style>
